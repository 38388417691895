import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import {ContentPageNode} from '../../types/content-types'
import MediaItem from './MediaItem'
import GenericLink from './Link'
import {Response} from '../../types/api-types'
import RichText from './RichText'

type Props = {
  page: ContentPageNode
  hideImageOnMobile?: boolean
  response: Response | null
}

const Hero = ({page, response, hideImageOnMobile = false}: Props) => {
  if (!page) {
    return null
  }
  const media = response?.included?.find(
    el => el.id === page.relationships.field_media?.data?.id,
  )
  return (
    <div className=" justify-content-center">
      <Container fluid>
        <Row>
          <Col
            lg={media ? '7' : '10'}
            className="c-hero__intro d-flex flex-column align-items-start justify-content-center  section-box"
          >
            {media && !hideImageOnMobile && (
              <MediaItem
                priority
                item={media}
                objectFit="contain"
                response={response}
                wrapperClass="c-hero__image--mobile w-75 d-lg-none  ml-auto"
              />
            )}
            <h1 className="c-hero__intro-title text-black font-weight-bolder w-100">
              {page.attributes.title}
            </h1>
            {page.attributes.field_description && (
              <RichText
                extraClass="c-hero__intro-description mt-0"
                html={page.attributes.field_description.processed}
              />
            )}
            {page.attributes.field_button && (
              <GenericLink
                extraClass={`btn mt-4 py-3 px-4 btn--orange c-hero__button d-flex align-items-center`}
                link={page.attributes.field_button}
              >
                {page.attributes.field_button.title}
              </GenericLink>
            )}
          </Col>
          <Col
            className={`${
              media
                ? 'c-hero__image d-none d-lg-flex mx-lg-0 p-0 align-items-start offset-xl-1'
                : 'd-none'
            }`}
          >
            {media && (
              <MediaItem
                response={response}
                priority
                item={media}
                objectFit="contain"
                layout="fill"
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Hero
