import React from 'react'
import {Response} from '../../types/api-types'
import {ContentPageNode} from '../../types/content-types'
import Hero from '../shared/Hero'

type Props = {
  page: ContentPageNode
  children: React.ReactNode
  response: Response
}

const Homepage = ({page, children, response}: Props) => {
  return (
    <>
      <Hero page={page} response={response} hideImageOnMobile={false} />
      {children}
    </>
  )
}

export default Homepage
